.header {
   padding: 20px 10px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   background-color: black;
   
} 
.logo {
   font-size: 2.0rem;
   color: white;
   font-family: 'Orbitron', sans-serif;

}
.nav-list {
   display: flex;

}
.nav-link {
   list-style: none;
}
.nav-link a{
   text-decoration: none;
   padding: 0 5px;
   font-size: 0.9em;
   font-weight: 400;
   letter-spacing: 0.5px;
   font-family: 'Oswald', sans-serif;
   text-transform: uppercase;
   color: rgb(223, 219, 174);
}