.about {
   font-family: 'Oswald', sans-serif;
   padding: 0 15px;
}
.about-title {
   margin: 20px 0;
   display: flex;
   justify-content: center;
   align-items: center;
   
   
}
.title-side {
   text-transform: uppercase;
   font-family: 'Oswald', sans-serif;
   letter-spacing: 1.5px;
   font-weight: 600;
}
.title-center{
   background-color: black;
   color: white;
   font-size: 2.5rem;
   padding: 0 10px;
   margin: 0 10px;
   font-family: 'Orbitron', sans-serif;
}
.services {
   border-bottom: 1px solid black;
   margin: 20px 0;
    
}
.services-title{
   text-align: right;
   padding-right: 20px;
}
.services-list{
   display: flex;
   justify-content: center;
}
.place-name{
   text-align: center;
   font-weight: 600;
   font-size: 1.5rem;
}
.place-address{
   text-align: center;
  
}
.map{
   margin-top: 10px;
   display: flex;
   justify-content: center;
}

